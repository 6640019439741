import { graphql, PageProps } from "gatsby";
import React from "react";
import { GatsbyImageProps } from "gatsby-plugin-image";

import { Page } from "../components/Page";
import { SEO } from "../components/SEO";

interface PageTemplateProps {
  page: {
    title: string;
    image?: {
      asset: GatsbyImageProps["image"];
    };
    body: string;
  };
}

const PageTemplate: React.FC<PageProps<PageTemplateProps>> = ({ data }) => {
  return (
    <>
      <SEO title={data.page.title} />
      <Page title={data.page.title} image={data.page.image}>
        {data.page.body}
      </Page>
    </>
  );
};

export const query = graphql`
  query ($slug: String!) {
    page: sanityPage(slug: { current: { eq: $slug } }) {
      body
      image {
        asset {
          gatsbyImageData
        }
      }
      title
    }
  }
`;

export default PageTemplate;
